<template>
  <header
    ref="header"
    class="!max-w-screen fixed top-0 z-20 flex w-screen justify-between bg-white lg:w-full"
    :class="{ 'z-1002': UIStore.hasOpenSidebar }"
  >
    <div class="flex w-full flex-col">
      <template v-if="is_master">
        <AdminHighlight />
      </template>
      <div class="flex w-full items-center justify-between px-4 py-6 lg:px-14">
        <div class="flex">
          <div class="!lg:items-start flex flex-col !items-end lg:flex-row lg:gap-14">
            <a
              v-if="whiteLabel === null || whiteLabel?.show_eletromidia_logo"
              :href="
                currentRoute.includes('resultados') || currentRoute === '/'
                  ? whiteLabel === null
                    ? '/'
                    : `/${whiteLabel?.code}`
                  : '/resultados'
              "
              class="flex h-full items-center justify-center"
            >
              <img
                loading="lazy"
                :src="LogotipoELT"
                :alt="'Logotipo Eletromidia Aqui'"
                :width="100"
                :class="[isMobile ? '!max-w-[50px]' : '!max-w-[76px]']"
              />
            </a>
            <a v-if="whiteLabel != null" :href="`/${whiteLabel?.code}`" class="flex h-full items-center justify-center">
              <img
                loading="lazy"
                :src="whiteLabel?.logo.image"
                :alt="`Logotipo ${whiteLabel?.name}`"
                :width="whiteLabel?.logo.width"
                :class="isMobile && '!max-w-[78px] mt-4'"
                class="block"
              />
            </a>
          </div>
        </div>
        <nav class="flex w-full items-center">
          <Button
            v-if="whiteLabel === null && props.path == '/'"
            class="ml-auto md:ml-20"
            type="button"
            @click="createAd"
          >
            <span class="!text-lg font-bold"> anunciar agora </span>
          </Button>

          <Menubar
            v-if="props.path === '/' && isMobile == false"
            :model="whiteLabel === null ? institutionalLinks : institutionalLinksWhiteLabel"
            class="none border-none bg-white !text-lg lg:block"
          />

          <!-- <Menubar
            v-if="!isMobile && !is_master"
            :model="whiteLabel === null ? institutionalLinks : institutionalLinksWhiteLabel"
            class="none menubar-items border-none bg-white text-base lg:block"
          /> -->

          <div class="ml-auto flex">
            <div v-if="userNameMessage.message && !isMobile" class="mr-5 flex min-w-fit items-center">
              <template v-if="is_master">
                <template v-if="emailOrNameAccountSelected">
                  <p class="ml-5 text-neutral-low-medium">Acessando conta de</p>
                  <span class="ml-2 rounded-sm px-2 py-0 text-xl font-bold text-primary-default">
                    <i class="uil uil-users-alt mr-1"></i> {{ emailOrNameAccountSelected }}
                  </span>
                </template>
                <Button outlined class="ml-8" type="button" @click="UIStore.toggleSidebar(SidebarEnum.ADMIN)">
                  <span class="px-3 font-semibold text-neutral-low-dark"> Opções Admin </span>
                  <i class="uil uil-cog" />
                </Button>
              </template>
              <template v-else>
                <template v-if="agency.accountsSelect && agency.accountsSelect.length > 1">
                  <div class="mr-5 flex items-center">
                    <p class="ml-6 mr-3 text-neutral-low-medium">Você está na conta de:</p>
                    <div class="flex items-center">
                      <Dropdown
                        v-model="account"
                        :filter="agency.accountsSelect.length > 15"
                        :options="agency.accountsSelect"
                        optionLabel="account.name"
                        append-to="self"
                        input-class="min-w-[130px] !p-[0.8rem]"
                        placeholder="Escolha uma conta..."
                        class="max-h-[36px]"
                        @change="showAccountDialog()"
                      />
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <Notifications v-if="isLogged()" class="mr-3 flex items-center" />

            <div v-if="verifyUrl()" class="relative flex empty:hidden">
              <span
                v-if="isLogged()"
                class="absolute inline-flex h-full w-full rounded-full bg-primary-default opacity-0"
                :class="{
                  'opacity-75 animate-ping': updateCart,
                }"
              />
              <Button
                v-if="props.path !== '/'"
                :key="mediaPlanLabel && cartCount"
                type="button"
                class="p-button p-button-outlined bt-mob btn-carrinho focus:!shadow- my-auto !overflow-visible"
                :style="{ backgroundColor: '#616161', color: '#FFFFFF' }"
                icon="uil uil-shopping-cart"
                :label="mediaPlanLabel"
                badgeClass="custom-badge"
                :badge="cartCount === 0 ? null : cartCount.toString()"
                @click="toggleSidebar(SidebarEnum.CART)"
              >
                <template #icon>
                  <i class="uil uil-shopping-cart lg:mr-3" :style="{ color: '#A9A9A9' }"></i>
                </template>
              </Button>
            </div>

            <Button
              type="button"
              aria-controls="overlay_menu"
              class="without-border p-button p-button-outlined header-button max-h-12 min-w-fit !text-neutral-low-dark focus:!shadow-none lg:ml-3"
              :class="userNameMessage.message && '!border-neutral-gray6'"
              @click="handleMenu"
            >
              <template v-if="isLogged() && userNameMessage.message">
                <!-- <template v-if="userProfilePhotoUrl || google_auth.profile">
                  <figure
                    class="h-[18px] w-[18px] rounded-md bg-cover"
                    :style="`background-image: url(${userProfilePhotoUrl || google_auth.profile})`"
                  />
                </template>
                <template v-else>
                  <Avatar v-if="name" :label="name.charAt(0)" />
                </template> -->
                <div class="mr-3">
                  <UserAvatar :photo="userProfilePhotoUrl || google_auth.profile" :name="name" />
                </div>
                <template v-if="!isMobile">
                  <span class="mx-3 whitespace-nowrap font-semibold text-neutral-low-dark">
                    {{ userNameMessage.message }}
                  </span>
                </template>
                <i class="uil-bars text-xl" />
              </template>
              <template v-else>
                <span class="px-3 !text-lg font-semibold text-neutral-low-dark"> entrar </span>
                <i class="uil-bars text-xl" />
              </template>
            </Button>
            <div class="relative">
              <Menu
                id="overlay_menu"
                ref="menu"
                :model="menuItems"
                class="overlay-menu !-left-64 !top-11 min-w-64"
                appendTo="self"
                popup
              >
                <template #submenuheader="{ item }">
                  <div class="flex w-full items-center">
                    <span :class="item.icon" class />
                    <span class="text-primary font-bold">{{ item.label }}</span>
                    <span :class="item.iconEnd" class="ml-auto" />
                  </div>
                </template>

                <template #item="{ item, props }">
                  <a
                    :href="item.url"
                    :target="item.target"
                    class="flex !w-full hover:bg-neutral-light7"
                    v-bind="props.action"
                  >
                    <span v-if="item.icon" :class="item.icon" class="mr-2" />
                    <span class="whitespace-nowrap !p-0">{{ item.label }}</span>
                    <span :class="item.iconEnd" class="ml-auto" />
                  </a>
                </template>
              </Menu>
            </div>
          </div>
        </nav>
      </div>
      <div class="w-full border-b border-neutral-high-light"></div>

      <NewsTicker v-if="!UserStore.has_paid_order && isAuthorizedRoute" />
    </div>

    <Dialog
      v-model:visible="accountDialog"
      class="account-dialog-confirm"
      modal
      header="Você está trocando de conta! "
      :style="{ width: '40rem' }"
    >
      <div class="mb-8">
        Você está mudando para acessar a conta de <strong>{{ account.account.name }}</strong
        >, gostaria de continuar?
      </div>
      <div class="flex justify-between gap-2">
        <Button label="Não" outlined @click.prevent="accountDialog = false" />

        <Button label="Trocar" @click.prevent="changeAccount()" />
      </div>
    </Dialog>
    <Sidebar
      v-model:visible="isAdminSidebarVisible"
      :blockScroll="true"
      class="min-w-[468px]"
      @hide="handleSidebarHide(SidebarEnum.ADMIN)"
    >
      <br /><br /><br />
      <template #header>
        <div class="align-items-center flex gap-2">
          <strong class="text-2xl font-bold">Opções Administrativas</strong>
        </div>
      </template>

      <section v-auto-animate class="flex flex-col">
        <div
          class="cursor-pointer text-lg text-black transition-all duration-300 hover:!text-[#ff4f00]"
          @click="!showSearchUser ? handleAdminOptions('search') : null"
        >
          <strong>Buscar por Nome, E-mail ou ID do Pedido. Ex: 422</strong> <i class="uil-search ml-4"></i>
        </div>

        <div v-if="showSearchUser" class="my-6 flex flex-col">
          <span class="relative mb-6 w-full">
            <i
              class="uil text-surface-400 dark:text-surface-600 absolute left-3 top-2/4 -mt-2"
              :class="isLoadingSearchAccount ? 'uil-spinner animate spin' : 'uil-search'"
            />
            <InputText
              v-model="searchAccountByAdmin"
              autofocus
              placeholder="Buscar por Nome, E-mail ou ID do Pedido. Ex: 422"
              class="!pl-10"
              @input="handleSearchAccountByNameOrEmail"
            />
          </span>
          <Dropdown
            v-model="account"
            :options="agency && agency.accountsSelect"
            optionLabel="account.name"
            :loading="isLoadingSearchAccount"
            :disabled="isLoadingSearchAccount || !agency.accountsSelect.length"
            placeholder="Selecione uma conta"
            class="w-full"
            @change="showAccountDialog()"
          />
        </div>
      </section>
      <span class="my-6 block h-[1px] w-full bg-neutral-light8" />
      <section v-auto-animate class="flex flex-col">
        <div
          class="mb-6 cursor-pointer text-lg text-black transition-all duration-300 hover:!text-[#ff4f00]"
          @click="!showCreateUser ? handleAdminOptions('create') : null"
        >
          <strong>Criar novo usuário</strong> <i class="uil-user-plus ml-4"></i>
        </div>

        <CreateNewUserForm :show="showCreateUser" />
      </section>
    </Sidebar>
  </header>
  <Cart :isCartSidebarVisible="isSidebarVisible('cart')" @close-cart-sidebar="handleSidebarHide('cart')" />
  <MenuSidebar
    v-if="isMobile"
    :is-mobile="props.isMobile"
    :is-menu-sidebar-visible="isSidebarVisible('menu')"
    :name="name"
    :photo="userProfilePhotoUrl || google_auth.profile"
    :message="userNameMessage.message"
    :menu-items="menuItems"
    :logo="LogotipoELT"
    @close="handleSidebarHide('menu')"
  />
</template>

<script setup>
import { SidebarEnum } from "@enums/SidebarEnum";
import { useStore } from "@nanostores/vue";
import Cookies from "js-cookie";
import debounce from "lodash/debounce";
import { storeToRefs } from "pinia";
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";

import LogotipoELT from "@/assets/logos/logo-eletromidia_aqui.svg";
import AdminHighlight from "@/components/AdminHighlight.vue";
import Cart from "@/components/Cart/Cart.vue";
import Notifications from "@/components/Notifications/Notifications.vue";
import NewsTicker from "@/components/pages/Home/NewsTicker.vue";
import { useIsMobile, usePersistentRoute, usePixelRatio } from "@/hooks";
import {
  rotaAnuncios,
  rotaBibliotecaMidias,
  rotaCadastro,
  rotaColaboradores,
  rotaContas,
  rotaDashboard,
  rotaIdentificacao,
  rotaPaginaInicial,
  rotaPerfil,
  rotaResultados,
} from "@/routes";
import { getUser } from "@/services/user";
import { $agency, fetchCollaborators, fetchUserAccountsSelect } from "@/stores/accounts";
import { useCartStore } from "@/stores/CartStore";
import { useUIStore } from "@/stores/UIStore";
import { useUserStore } from "@/stores/UserStore";
import { trackButton } from "@/utils/analitycsHelper/button";
import * as analitycsTransactionHelper from "@/utils/analitycsHelper/transaction";
import { wrapperTrackerTransaction } from "@/utils/analitycsHelper/transaction";
import Tracker from "@/utils/Tracker/Tracker";
import validateEmail from "@/utils/validateEmail";
import validateName from "@/utils/validateName";

import CreateNewUserForm from "./Admin/CreateNewUserForm.vue";
import MenuSidebar from "./Sidebars/MenuSidebar/MenuSidebar.vue";
import UserAvatar from "./UserAvatar.vue";

const { proxy } = getCurrentInstance();
const { currentRoute } = usePersistentRoute();
const { pixelRatio } = usePixelRatio();
const { isMobile } = useIsMobile();
// usePersistentRoute();
const CartStore = useCartStore();
const UserStore = useUserStore();
const UIStore = useUIStore();
const { activeSidebar } = storeToRefs(UIStore);

const { cartCount } = storeToRefs(CartStore);
const { is_master, is_partner, account_id, id, name, email, google_auth, token } = storeToRefs(UserStore);

const updateCart = ref(false);
const menu = ref();
const header = ref();
const userProfilePhotoUrl = ref("");
const emailOrNameAccountSelected = ref("");
const mediaPlanLabel = ref("");

const account = ref("");
const searchAccountByAdmin = ref("");
const isLoadingSearchAccount = ref(false);
const accountDialog = ref(false);
const isAdminSidebarVisible = ref(UIStore.isSidebarOpen("admin"));
const agency = useStore($agency);

const showCreateUser = ref(false);
const showSearchUser = ref(false);
const isAuthorizedRoute = ref(false);

const handleAdminOptions = (option) => {
  switch (option) {
    case "search":
      showSearchUser.value = true;
      showCreateUser.value = false;
      break;
    case "create":
      showCreateUser.value = true;
      showSearchUser.value = false;
      break;
    default:
      console.warn("opção invalida:", option);
      break;
  }
};

const userNameMessage = reactive({});
const googleSoftLogin = reactive({});
const institutionalLinks = ref([
  {
    label: "agências parceiras",
    url: "https://parceiros.eletromidia.com.br/?utm_source=site&utm_medium=na&utm_campaign=site_aqui",
    class: "font-bold",
    target: "_blank",
  },
]);

const createAd = () => {
  Tracker.getInstance().track(Tracker.EVENT_BUTTON_ANUNCIAR_AGORA, trackButton("Anunciar agora cabeçalho"));
  if (UserStore.email || UserStore.google_auth.email) {
    return (document.location.href = rotaResultados.url);
  } else {
    return (document.location.href = rotaIdentificacao.url + "?from=cart");
  }
};

const institutionalLinksWhiteLabel = ref([]);

const hasInviteOrAcceptedAccount = computed(() => {
  const { accountsPermissions } = agency.value;

  return accountsPermissions.length > 0;
});

const menuItems = computed(() => {
  const handleLogout = {
    label: "sair",
    iconEnd: "uil uil-signout",
    command: () => {
      localStorage.clear();
      Cookies.remove("token");
      Cookies.remove("__wl");
      Cookies.remove("hasEmail");
      Cookies.remove("account_id");
      Cookies.remove("partnerUser");
      document.location.href = rotaPaginaInicial.url;
    },
  };

  if (googleSoftLogin.validate) {
    const linksFromGoogle = [
      {
        label: "complementar",
        url: rotaCadastro.url,
        icon: "uil uil-user",
      },
      handleLogout,
    ];

    return linksFromGoogle;
  }

  if (isLogged()) {
    const links = [
      {
        label: "Meu histórico",
        iconEnd: "uil uil-user",
        items: [
          {
            label: "Criar Novo Anúncio",
            url: rotaResultados.url,
          },
          {
            label: "Meus Anúncios",
            url: rotaAnuncios.url,
          },
          {
            label: "Biblioteca de Midias",
            url: rotaBibliotecaMidias.url,
          },
        ],
      },
      ...(agency.value && hasInviteOrAcceptedAccount.value
        ? [{ label: "contas", url: rotaContas.contas.url, icon: "uil uil-users" }]
        : []),
      ...(UserStore.is_partner
        ? [{ label: "Dashboard", url: rotaDashboard.url, target: "", icon: "uil uil-chart-bar" }]
        : []),
      {
        separator: true,
      },
      {
        label: "Gerenciador",
        iconEnd: "uil uil-cog",
        items: [
          {
            label: "Meu Perfil",
            url: rotaPerfil.url,
          },
          {
            label: "Minhas Contas",
            url: rotaContas.contas.url,
          },
          {
            label: "Meus Colaboradores",
            url: rotaColaboradores.url,
          },
          {
            label: "Programa de Indicação",
            url: "https://home.eletromidia.com.br/aqui/programadeindicacao?bl_ref=D5ND2",
            target: "_blank",
          },
        ],
      },
      {
        separator: true,
      },
      {
        label: "Materiais e ajuda",
        iconEnd: "uil uil-info-circle",
        items: [
          {
            label: "Mídia Kit",
            url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/MIDIA_KIT_INSTITUCIONAL_29_04_24.pdf",
            target: "_blank",
          },
          {
            label: "Especificações para Criação",
            url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/2024/10/smb-especificacoes-tecnicas-out24.pdf",
            target: "_blank",
          },
          {
            label: "Guia de Criação",
            url: "https://storage.googleapis.com/elt-smb-prod/assets/docs/2024/04/guia-pratico-de-criacao-para-out-of-home-eletromidia.pdf",
            target: "_blank",
          },
          {
            label: "Central de conhecimento",
            url: "https://materiais.eletromidia.com.br/aqui/central-do-conhecimento",
            target: "_blank",
          },
          {
            label: "Suporte",
            url: "https://api.whatsapp.com/send/?phone=%2B5511963815044&text=&type=phone_number&app_abs[…]e=whatsapp&utm_medium=whatsapp_aqui&utm_campaign=footer_aqui",
            target: "_blank",
          },
        ],
      },
      {
        separator: true,
      },
      handleLogout,
    ];

    return links;
  }

  const links = [
    {
      label: "login",
      url: rotaIdentificacao.url,
      icon: "uil uil-user",
    },
    {
      label: "quero me cadastrar",
      url: rotaCadastro.url,
      icon: "uil uil-user",
    },
    {
      separator: true,
    },
    {
      label: "criar novo anúncio",
      url: rotaResultados.url,
      icon: "uil uil-desktop",
      iconEnd: "uil uil-plus",
    },
  ];

  return links;
});

const props = defineProps({
  path: {
    type: String,
  },
  whiteLabel: {
    type: Object,
  },
});

const verifyUrl = () => {
  return (
    !props.path.startsWith("/pagamento") &&
    !props.path.startsWith("/identificacao") &&
    !props.path.startsWith("/cadastro")
  );
};

const isLogged = () => {
  return UserStore.token && UserStore.email;
};

const redirectToResume = () => {
  document.location.href = "/resumo";
};

const getUsername = () => {
  googleSoftLogin.validate = !token && google_auth.fromGoogle;

  if (google_auth.name !== undefined && google_auth.name !== "") {
    userNameMessage.message = `Olá, ${google_auth.name.split(" ")[0]}!`;
    return;
  }

  userNameMessage.message = name.value ? `Olá, ${name.value.split(" ")[0]}!` : null;
};

const toggle = (event) => {
  menu.value.toggle(event);
};

const getHeight = () => {
  if (header.value) {
    return header.value.clientHeight;
  }
};

const dispatchFetchCart = async () => {
  CartStore.fetchCart();
};

const toggleSidebar = (name) => {
  if (props.path.includes("enviar-arquivo")) {
    redirectToResume();
  }
  UIStore.toggleSidebar(name);

  if (UIStore.isSidebarOpen(SidebarEnum.CART)) {
    analitycsTransactionHelper.wrapperTrackerTransaction(CartStore, Tracker.EVENT_VIEW_CART);
  }
};

const handleSidebarHide = (name) => {
  UIStore.closeSidebar(name);
};

const isSidebarVisible = (name) => {
  return UIStore.isSidebarOpen(name);
};

const handleMenu = (event) => {
  isMobile.value ? UIStore.toggleSidebar("menu") : toggle(event);
};

const handleMediaPlanLabel = () => {
  mediaPlanLabel.value = isMobile.value ? "" : "plano de mídia";
};

watch(
  () => UIStore.activeSidebars,
  () => {
    isAdminSidebarVisible.value = UIStore.isSidebarOpen(SidebarEnum.ADMIN);
  },
  { deep: true },
  { immediate: true },
);

onMounted(() => {
  const height = getHeight();
  dispatchFetchCart();

  const isClosedPromoBanner = localStorage.getItem("isClosedPromoBanner");

  if (!isClosedPromoBanner) {
    document.body.style.paddingTop = height + "px";
  }
  handleMediaPlanLabel();
  getUsername();

  isAuthorizedRoute.value = window.location.pathname === rotaPaginaInicial.url;
});

watch(cartCount, (newCount) => {
  updateCart.value = true;
  setTimeout(() => {
    updateCart.value = false;
  }, 500);
});

/**
 * Agency Accounts
 */
const showAccountDialog = () => {
  if (account.value != null) accountDialog.value = true;
};
const changeAccount = () => {
  Cookies.set("account_id", account.value.account_id);
  localStorage.setItem("account_name", account.value.account.name);
  window.location.reload();
};

watch(agency, (n) => {
  let accountIdFromCookie = Cookies.get("account_id");
  let accountSelected;
  const accountsSelectList = n?.accountsSelect ?? [];
  const accId = accountIdFromCookie ?? account_id;

  if (accountsSelectList.length >= 1) {
    accountSelected = accountsSelectList.find((el) => accId == el.account_id);
    account.value = accountSelected;
  }
});

watch(
  agency,
  () => {
    isLoadingSearchAccount.value = false;
  },
  { deep: true },
);

watch(
  () => email,
  async () => {
    if (!email.value) {
      return;
    }

    const userIdHash = await Tracker.hash(email.value);
    Tracker.getInstance().track(Tracker.EVENT_USER_DATA, {
      user_id: userIdHash,
    });
  },
  { immediate: true },
);

onMounted(async () => {
  if (agency.value.accountsSelect.length <= 0 && isLogged()) {
    fetchUserAccountsSelect();
  }
});

onMounted(() => {
  isLogged() && fetchCollaborators();
  fetchUserInfo(id.value);

  if (is_master) {
    handleGetAccount();
  }

  if (UserStore.id && UserStore.email) {
    chmln.identify(UserStore.id, {
      email: UserStore.email,
      name: UserStore.name,
    });
  }
});
onMounted(() => {
  const header = document.querySelector("header");
  let zoom;

  if (pixelRatio.value === 1) {
    zoom = 1; // Não faz nada, mantém o zoom como 1
  } else if (pixelRatio.value === 1.5) {
    zoom = 0.8;
  } else if (pixelRatio.value === 1.25) {
    zoom = 0.9;
  }

  const headerHeight = header.offsetHeight * zoom;
  document.body.style.paddingTop = `${headerHeight}px`;
});

const handleSearchAccountByNameOrEmail = debounce(() => {
  if (isLogged()) {
    fetchUserAccountsSelect({ search: searchAccountByAdmin.value });
    isLoadingSearchAccount.value = true;
  }
}, 750);

const fetchUserInfo = async (id) => {
  if (!id) {
    return;
  }

  try {
    const userData = await getUser(id);
    userProfilePhotoUrl.value = userData.profile || "";
  } catch (error) {
    console.error("Erro ao carregar usuario", error);
  }
};

const handleGetAccount = async () => {
  if (!email) return;

  const account_name = await localStorage.getItem("account_name");

  emailOrNameAccountSelected.value = account_name;
};

const handleScroll = () => {
  if (!isMobile.value && menu.value && menu.value.visible) {
    menu.value.hide();
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
:deep(.p-tieredmenu.p-tieredmenu-overlay) {
  top: 37px !important;
  right: 0 !important;
  left: auto !important;
}

#accounts-select {
  width: 195px;
  border-radius: 8px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-button.btn-carrinho .p-badge.custom-badge) {
  top: -6px;
  right: 5px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-weight: normal;
  @apply bg-status-positive-light;

  @media screen and (max-width: 768px) {
    right: 2px !important;
  }
}

.menubar-items {
  @media (min-width: 1280px) and (max-width: 1366px) {
    font-size: 0.8rem !important;
  }
}

.p-button.without-border {
  border: none !important;
}
//removendo bg de acessibilidade padrão do componente
:deep(.overlay-menu.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content) {
  background: transparent;
}
//forçando hover para cinza que era a cor de foco
:deep(.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link:hover) {
  background: #e5e7eb;
}
</style>
